import * as Sentry from '@sentry/angular';

import { NetworkError, SentryProvider } from '@ntrx/error-handler';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SentryService implements SentryProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public captureExceptionInSentry(error: any): void {
    if (error instanceof NetworkError) {
      switch (error.status) {
        case 0:
        case 401:
        case 403:
          return; // Don't capture errors with 401 and 403 http status code.
        default:
          Sentry.captureException(error);
          break;
      }
    } else {
      Sentry.captureException(error.originalError || error);
    }
  }
}
