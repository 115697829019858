import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TopNavigationComponent } from '@components/top-navigation/top-navigation.component';

@Component({
  selector: 'dc-main',
  standalone: true,
  imports: [RouterOutlet, TopNavigationComponent],
  template: `
    <main>
      <dc-top-navigation />
      <router-outlet />
    </main>
  `,
  styleUrl: './main.component.scss',
})
export class MainComponent {}
