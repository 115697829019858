import { ResolveFn } from '@angular/router';
import { User } from '@models/index';
import { UserService } from '@services/index';
import { filter } from 'rxjs';
import { inject } from '@angular/core';
import { isNonNull } from '@utilities/is-non-null';

export const userMeResolver: ResolveFn<User> = () => {
  const userService = inject(UserService);
  return userService.me$.pipe(filter(isNonNull));
};
