import { AuthGuard } from '@ntrx/auth';
import { MainComponent } from '@components/main/main.component';
import { Routes } from '@angular/router';
import { userMeResolver } from '@resolver/index';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: {
      user: userMeResolver,
    },
    children: [
      {
        path: 'incoming-data',
        loadChildren: () => import('@features/incoming-data/incoming-data.routes'),
      },
      {
        path: 'core-data',
        loadChildren: () => import('@features/core-data/core-data.routes'),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/incoming-data',
  },
];
