import { ApplicationConfig, LOCALE_ID, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { ErrorModalProvider, SentryProvider, provideErrorHandler } from '@ntrx/error-handler';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ModalService } from '@core/services';
import { SentryService } from './sentry.service';
import { environment } from '@environments/environment';
import localeDe from '@angular/common/locales/de';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAuthClient } from '@ntrx/auth';
import { provideMarkdown } from 'ngx-markdown';
import { provideToastr } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import { routes } from './app.routes';

registerLocaleData(localeDe);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideMarkdown(),
    provideAuthClient(
      {
        clientId: environment.oauth.clientId,
        scope: environment.oauth.scopes,
        authorizeEndpoint: environment.oauth.authHost + '/oauth/authorize',
        tokenEndpoint: environment.oauth.authHost + '/oauth/token',
        redirectUri: window.location.origin + environment.oauth.callbackURL,
        logoutUrl: environment.oauth.authHost + '/logout',
        postLogoutRedirectUri: window.location.origin + '/login',
        requireHttps: environment.oauth.requireHttps,
      },
      [environment.apiBaseURL]
    ),
    provideErrorHandler({
      enableSentry: true,
      customReloadFunction: window.location.reload,
      preventLoggingInConsole: false,
      reloadAtChunkLoadingError: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideToastr({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    importProvidersFrom([
      FormlyModule.forRoot({
        validationMessages: [{ name: 'required', message: 'This field is required' }],
      }),
      FormlyBootstrapModule,
    ]),
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: ErrorModalProvider, useClass: ModalService },
    { provide: SentryProvider, useClass: SentryService },
  ],
};
