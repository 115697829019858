/// <reference types="@angular/localize" />

import * as Sentry from '@sentry/angular';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    release: environment.sentry.version,
    environment: environment.sentry.environment,
  });
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
